/* custom title styles */
.title-h1 {
    font-family: 'Oddval';
    font-style: normal;
    font-weight: 600;

    text-align: center;


    color: #1C1B1F;

    font-size: 32px;
    line-height: 40px;

    &-active{
        color: '#fff'
    }
}
.title-h2 {
    font-family: 'Oddval';
    font-style: normal;
    font-weight: 600;

    text-align: center;


    color: #000000;

    font-size: 32px;
    line-height: 62px;
}
.title-h3 {
    font-family: 'Oddval';
    font-style: normal;
    font-weight: 600;

    text-align: left;


    color: #1C1B1F;

    font-size: 24px;
    line-height: 37px;
}

.title-h4 {
    font-family: 'Oddval';
    font-style: normal;
    font-weight: 600;

    color: #1C1B1F;

    font-size: 16px;
    line-height: 24px;
}
