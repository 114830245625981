*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  box-sizing: border-box;
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
}

body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  margin: 0;

  font-family: 'Roboto', Arial, Helvetica, sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
header {
  /* 0 flex-grow, 0 flex-shrink, auto flex-basis */
  flex: 0 0 auto;
}

main {
  /* 1 flex-grow, 0 flex-shrink, auto flex-basis */
  flex: 1;
  @media screen and (max-width: 743px) {
    padding-top: 65px;
  }
}

footer {
  /* 0 flex-grow, 0 flex-shrink, auto flex-basis */
  flex: 0 0 auto;
}

h1,
h2,
h3,
h4,
h5,
h6,
label,
ul,
ol,
p,
a {
  margin: 0;
  padding: 0;
  list-style: none;
  text-decoration: none;
}

a,
a:visited {
  text-decoration: none;
  border: none;
  background: transparent;
}

img {
  display: block;
  max-width: 100%;
  height: auto;
  border: none;
}

img,
embed,
iframe,
object,
audio,
video {
  height: auto;
  max-width: 100%;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/* custom font family styles */

@font-face {
  font-family: 'Oddval';
  src: url('../assets/fonts/oddval/Oddval-SemiBold.ttf');
}

/* scroll bar*/
@import './components/scroll.css';

/* custom logo styles */
@import './components/logo.css';

/* custom title styles */
@import './components/title.css';

/* rating style */
@import './components/rating.css';

/* custom calendar styles */
@import './components/calendar.css';
