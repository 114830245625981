/* scroll bar*/

main::-webkit-scrollbar {
    width: 4px;
}

/* .css-4eq9fo::-webkit-scrollbar-track {

} */
main::-webkit-scrollbar-thumb {

    background: #79747E;
    border: 1px solid #79747E;
    border-radius: 4px;
}