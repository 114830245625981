    .rdrCalendarWrapper {
        background-color: transparent !important;
    }

    .rdrDateDisplayWrapper {
        width: '100%';
    }

    /* header calendar*/

    .rdrDateDisplay {
        position: fixed;
        z-index: 2;
        width: 100%;

        top: 50px;
        left: 50%;
        transform: translateX(-50%);
    }

    .rdrDateDisplayItemActive {
        border-color: transparent !important;
        background-color: '#F7F6FA' !important;
    }

    .rdrDateDisplayItem {
        border-radius: 0 !important;
        background-color: transparent !important;
        box-shadow: none !important;
        border: none !important;

    }

    .rdrDateInput {
        border-radius: 0 !important;
        background-color: transparent !important;
        box-shadow: none !important;
        border: none !important;
    }

    .rdrDateInput+.rdrDateDisplayItem {
        border-radius: 0 !important;
        background-color: transparent !important;
        box-shadow: none !important;
        border: none !important;

    }

    .rdrDateInput+.rdrDateDisplayItem+.rdrDateDisplayItemActive {
        border-radius: 0 !important;
        background-color: transparent !important;
        box-shadow: none !important;
        border: none !important;


    }

    .rdrDateDisplayItem input {
        font-family: 'Oddval';
        font-style: normal;
        font-weight: 600;
        font-size: 22px;
        line-height: 28px;

        color: #1C1B1F !important;
        text-transform: capitalize;
    }

    /* month choose calendar*/

    .rdrMonthAndYearWrapper {
        width: '100%';
    }

    .rdrMonthPicker,
    .rdrYearPicker {
        font-family: 'Oddval' !important;
        text-transform: capitalize;
    }

    .rdrMonthPicker>select,
    .rdrYearPicker>select {
        font-family: 'Oddval' !important;
        text-transform: capitalize;

        &:hover {
            background-color: transparent !important;
            border: 1px solid #6750A4;
        }
    }

    .rdrMonthAndYearWrapper>button {
        background-color: transparent !important;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        width: 48px;
        height: 48px;
        margin: 0;

        &:hover {
            background-color: transparent !important;
            border: 1px solid #6750A4;
        }
    }

    .rdrNextButton {
        margin: 0
    }

    .rdrWeekDays {
        width: 100%;
    }

    .rdrMonths{
        gap: 35px;
        min-height:auto;
    }

    .rdrMonth {
        padding: 0 !important;
        height: auto;
        width: calc(7 * 48px);
    }

    .rdrWeekDay {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        text-transform: capitalize;

        display: block;
        width: 48px;
        height: 48px;
        color: #1C1B1F
    }

    .rdrDays {
        width: calc(7 * 48px);
        height: 288px;
    }

    .rdrDay {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 48px;
        height: 48px !important;

        & span {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 48px;
            height: 48px !important;
        }
    }
    .rdrDayNumber{
        font-family: 'Roboto';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;

        &:hover {
                border: none;
            }
            & span::after {
                display: none}
    }

    .rdrDayEndPreview,
    .rdrDayStartPreview {
        border-color: transparent !important;        
    }

    .rdrMonthName{
    font-family: 'Oddval';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;

    letter-spacing: 0.1px;

    color: #49454F !important;

    text-transform: capitalize;
    }


        @media screen and (min-width: 744px) {
        .rdrDateDisplay{ display: none !important}
        }